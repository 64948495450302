import React from 'react';

import './Loader.scss';

export default function Loader() {
  return (
    <div className="cp-loader">
      <div className="preloader-wrapper big active">
        <div className="spinner-layer spinner-blue-only">
          <div className="circle-clipper left">
            <div className="circle" />
          </div>
          <div className="gap-patch">
            <div className="circle" />
        </div><div className="circle-clipper right">
          <div className="circle" />
        </div>
        </div>
      </div>
    </div>
  );
}
